import axios from "axios";
import { formatEther, isAddress } from "ethers/lib/utils";
import { LOOKSRARE_BASE_URL, LOOKSRARE_API_BASE_URL } from "../constants/urls";

export const isLooksrareUrl = (url, networkKeyName) => {
  return url.startsWith(LOOKSRARE_BASE_URL[networkKeyName]);
};

export const fetchNftAddressByLrCollectionUrl = (url, networkKeyName) => {
  if (!isLooksrareUrl(url, networkKeyName)) return null;
  const address = url.split("/").pop();
  return isAddress(address) ? address : null;
};

export const fetchNftAddressByLrAssetUrl = (url, networkKeyName) => {
  if (!isLooksrareUrl(url, networkKeyName)) return null;
  const address = url.split("/")[url.split("/").length - 2];
  return isAddress(address) ? address : null;
};

export const fetchLrCollectionStatsByNftAddress = async (
  address,
  networkKeyName
) => {
  if (!networkKeyName) return null;
  const url = `${LOOKSRARE_API_BASE_URL[networkKeyName]}/v1/collections/stats?address=${address}`;
  try {
    const response = await axios.get(url);
    return response.data.data;
  } catch (e) {
    console.error(e);
    return null;
  }
};
