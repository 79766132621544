import { Network, initializeAlchemy } from "@alch/alchemy-sdk";
const alchemySettings = {
  apiKey: process.env.REACT_APP_ALCHEMY_ID,
  network: Network.ETH_RINKEBY,
  maxRetries: 10,
};

export const alchemySdk = initializeAlchemy(alchemySettings);

export const OPENSEA_API_KEY = "766d5e206b5a45729cf60f4d509be087";
