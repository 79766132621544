import { Button, Avatar, Grid, Link, Typography, Card } from "@mui/material";
import openseaLogo from "../assets/logo-opensea.png";
import looksrareLogo from "../assets/logo-looksrare.png";
import { useEffect, useState } from "react";
import {
  fetchOsCollectionInfoBySlug,
  fetchOpenseaInfoByOsContractAddress,
} from "../utils/opensea";
import { fetchLrCollectionStatsByNftAddress } from "../utils/looksrare";
import { useNetwork } from "wagmi";
import { NETWORK_KEY_NAME } from "../constants/networks";
import { LOOKSRARE_BASE_URL, OPENSEA_BASE_URL } from "../constants/urls";
import { formatEther } from "ethers/lib/utils";

const getOpenseaInfo = async (nftAddress, networkKeyName) => {
  const nftInfo = await fetchOpenseaInfoByOsContractAddress(
    nftAddress,
    networkKeyName
  );
  if (!nftInfo) return null;
  const collectionInfo = await fetchOsCollectionInfoBySlug(
    nftInfo.slug,
    networkKeyName
  );
  return {
    slug: nftInfo.slug,
    floorPrice: collectionInfo && collectionInfo.stats.floor_price,
  };
};

const getLooksrareInfo = async (nftAddress, networkKeyName) => {
  const collectionStats = await fetchLrCollectionStatsByNftAddress(
    nftAddress,
    networkKeyName
  );
  if (!collectionStats) return null;
  const floorPrice = collectionStats.floorPrice;
  return formatEther(floorPrice);
};

export const FloorPrice = ({ marketName, nftAddress }) => {
  const logoImage = marketName === "opensea" ? openseaLogo : looksrareLogo;
  const [collectionSlug, setCollectionSlug] = useState("");
  const [floorPrice, setFloorPrice] = useState("———");
  const { activeChain } = useNetwork();
  const networkKeyName = activeChain ? NETWORK_KEY_NAME[activeChain.id] : null;

  let url = "";
  switch (marketName) {
    case "opensea":
      url = `${OPENSEA_BASE_URL[networkKeyName]}/collection/${collectionSlug}`;
      break;
    case "looksrare":
      url = `${LOOKSRARE_BASE_URL[networkKeyName]}/collections/${nftAddress}`;
      break;
    default:
      console.error("Unknown marketName");
  }

  useEffect(() => {
    setTimeout(() => {
      switch (marketName) {
        case "opensea":
          getOpenseaInfo(nftAddress, networkKeyName).then((data) => {
            if (data && data.slug) setCollectionSlug(data.slug);
            if (data && data.floorPrice) setFloorPrice(data.floorPrice);
          });
          break;

        case "looksrare":
          getLooksrareInfo(nftAddress, networkKeyName).then((floorPrice) => {
            if (floorPrice) setFloorPrice(floorPrice);
          });
          break;

        default:
          console.error("Unknown marketName");
      }
    }, 1000);
  }, []);

  return (
    <Link href={url} target="_blank" rel="noopener">
      <Button
        variant="outlined"
        color={marketName === "looksrare" ? "success" : "primary"}
      >
        <Grid container alignItems="center" justifyContent="center">
          <Grid item paddingRight="8px">
            <Avatar
              alt={marketName}
              src={logoImage}
              sx={{ height: "30px", width: "30px" }}
            />
          </Grid>
          <Grid item>
            <Typography>{`Floor $: ${floorPrice} ETH`}</Typography>
          </Grid>
        </Grid>
      </Button>
    </Link>
  );
};
