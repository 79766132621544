import { BigNumber, constants } from "ethers";

const dataUriToJson = (dataUri) => {
  return JSON.parse(Buffer.from(dataUri.substring(29), "base64"));
};

export const getContractDeploymentInfo = (contractName, networkKeyName) => {
  try {
    return require(`../deployments/${networkKeyName}/${contractName}.json`);
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getContractForWagmi = (
  contractName,
  networkKeyName,
  overrideAddress = null
) => {
  const deploymentInfo = getContractDeploymentInfo(
    contractName,
    networkKeyName
  );
  if (!deploymentInfo) return null;
  return {
    addressOrName: overrideAddress || deploymentInfo.address,
    contractInterface: deploymentInfo && deploymentInfo.abi,
  };
};

export const isExpiredInSeconds = (seconds) => seconds < Date.now() / 1000;

export const convertMillisecondsToSeconds = (milliseconds) =>
  milliseconds / 1000;

export const getNextFriday = (date = new Date()) => {
  const dateCopy = new Date(date.getTime());

  const nextFriday = new Date(
    dateCopy.setDate(
      dateCopy.getDate() + ((7 - dateCopy.getDay() + 5) % 7 || 7)
    )
  );

  return nextFriday;
};

export const getNextFridayUTC = (date = new Date()) => {
  const nextFriday = getNextFriday(date);
  const nextFridayUTC = Date.UTC(
    nextFriday.getFullYear(),
    nextFriday.getMonth(),
    nextFriday.getDate()
  );
  return nextFridayUTC;
};

export const formatPremium = (value) => {
  const hasPoint = value.includes(".");
  const integerPart = parseInt(value.split(".")[0]);
  const decimalPart = hasPoint ? value.split(".")[1] : "";
  let result = parseInt(integerPart) * 10 ** 5;
  switch (decimalPart.length) {
    case 1:
      result += parseInt(decimalPart) * 10 ** 4;
      break;
    case 2:
      result += parseInt(decimalPart) * 10 ** 3;
      break;
    default:
      break;
  }
  return result;
};

export const isDevelopment = () => {
  return !process.env.NODE_ENV || process.env.NODE_ENV === "development";
};

export const decodeTokenId = (tokenId) => {
  const securityDepositUnit = BigNumber.from(10).pow(13);

  const decodedTokenId = BigNumber.from(tokenId);
  const securityDeposit = decodedTokenId
    .div(BigNumber.from(2).pow(64))
    .mul(securityDepositUnit);
  const expiryDatetimeSec = decodedTokenId.mask(64).toNumber();
  return {
    securityDeposit: securityDeposit,
    expiryDatetimeSec: expiryDatetimeSec,
  };
};

export const generateFuturesDebtsList = (futuresList, debtsList) => {
  let tokenIdMap = {};
  // get futures info by tokenId
  for (let futures of futuresList) {
    if (!tokenIdMap[futures.tokenId]) {
      tokenIdMap[futures.tokenId] = {
        futures: {},
        debt: {},
      };
    }
    tokenIdMap[futures.tokenId].futures = {
      balance: parseInt(futures.balance),
      tokenId: futures.tokenId,
      tokenUri: futures.tokenUri.raw,
    };
  }

  // get debts info by tokenId
  for (let debt of debtsList) {
    if (!tokenIdMap[debt.tokenId]) {
      tokenIdMap[debt.tokenId] = {
        futures: {},
        debt: {},
      };
    }
    tokenIdMap[debt.tokenId].debt = {
      balance: parseInt(debt.balance),
      tokenId: parseInt(debt.tokenId),
      tokenUri: debt.tokenUri.raw,
    };
  }

  const positionsList = Object.keys(tokenIdMap).map((tokenId) => {
    // TODO: Should have `securityDeposit` and `expiry` properties.
    // const metadata = dataUriToJson(tokenIdMap[tokenId].futures.tokenUri);
    const { securityDeposit, expiryDatetimeSec } = decodeTokenId(tokenId);
    return {
      id: tokenId,
      futuresBalance: tokenIdMap[tokenId].futures.balance || 0,
      debtsBalance: tokenIdMap[tokenId].debt.balance || 0,
      expiryDatetimeSec: expiryDatetimeSec,
      securityDeposit: securityDeposit,
      fulfillment: 0,
    };
  });
  return positionsList;
};

export const isZeroAddress = (address) => address === constants.AddressZero;
