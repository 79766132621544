import { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardMedia,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  Typography,
  TextField,
  Link,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import {
  useAccount,
  useContractRead,
  useContractWrite,
  useNetwork,
  useWaitForTransaction,
} from "wagmi";
import { getContractForWagmi } from "../utils";
import { toast } from "react-toastify";
import { NETWORK_KEY_NAME } from "../constants/networks";
import { BigNumber, constants, utils } from "ethers";
import moment from "moment";
import { LoadingButton } from "@mui/lab";
import GetAppIcon from "@mui/icons-material/GetApp";
import { loadOpenseaListingInfo } from "../slices/FuturesSlice";
import { formatEther } from "ethers/lib/utils";

const FulfillByNftDialog = ({
  isOpen,
  closeDialog,
  debtsBalance,
  futuresAddress,
  tokenId,
  nftAddress,
  refreshCallback,
}) => {
  const [selectedTargetTokenIdList, setSelectedTargetTokenIdList] = useState(
    []
  );
  const ownedNfts = useSelector((state) => state.account.ownedNfts);
  const { data: account } = useAccount();
  const { activeChain } = useNetwork();
  const chainId = activeChain && activeChain.id;
  const networkKeyName = NETWORK_KEY_NAME[chainId];
  const nftContract = {
    addressOrName: nftAddress,
    contractInterface: require("../abis/IERC721.json"),
  };
  const { data: isApprovedForAll } = useContractRead(
    nftContract,
    "isApprovedForAll",
    {
      args: [account.address, futuresAddress],
    }
  );

  const { data: setApprovalForAllTxData, write: setApprovalForAll } =
    useContractWrite(nftContract, "setApprovalForAll", {
      args: [futuresAddress, !isApprovedForAll],
      onSettled: (data, error) => {
        if (data) toast.info(`hash: ${data.hash}`);
      },
      onError: (err) => {
        console.error(err);
      },
    });

  const { isLoading: isApprovingForAll } = useWaitForTransaction({
    hash: setApprovalForAllTxData && setApprovalForAllTxData.hash,
    wait: setApprovalForAllTxData && setApprovalForAllTxData.wait,
    onError: (err) => {
      console.error(err);
    },
    onSuccess: (data) => {
      toast.info("Approved");
    },
  });

  const { data: txData, write } = useContractWrite(
    getContractForWagmi("FuturesFor721", networkKeyName, futuresAddress),
    "fulfillDebt",
    {
      args: [selectedTargetTokenIdList, tokenId, []],
      onSettled: (data, error) => {
        if (data) toast.info(`hash: ${data.hash}`);
      },
      onError: (err) => {
        console.error(err);
      },
    }
  );

  const { isLoading: isTxLoading } = useWaitForTransaction({
    hash: txData && txData.hash,
    wait: txData && txData.wait,
    onError: (err) => {
      console.error(err);
    },
    onSuccess: (data) => {
      toast.info("Fulfilled");
      refreshCallback();
      closeDialog();
    },
  });

  const isReachFulfillBalance =
    selectedTargetTokenIdList.length >= debtsBalance;

  const selectTokenId = (selectedTargetTokenId) => {
    if (isReachFulfillBalance) return;
    setSelectedTargetTokenIdList([
      ...selectedTargetTokenIdList,
      selectedTargetTokenId,
    ]);
  };

  const unselectTokenId = (unselectedTargetTokenId) => {
    setSelectedTargetTokenIdList(
      selectedTargetTokenIdList.filter(
        (targetTokenId) => targetTokenId !== unselectedTargetTokenId
      )
    );
  };

  return (
    <Dialog open={isOpen} onClose={closeDialog} maxWidth="sm" fullWidth={true}>
      <DialogContent>
        <Typography>
          Select the NFT that you want to pay for the debt.
        </Typography>
        <LoadingButton
          loading={isApprovingForAll}
          onClick={() => setApprovalForAll()}
          variant="contained"
        >
          {isApprovedForAll ? "Unapprove" : "Approve"}
        </LoadingButton>
        <ImageList
          sx={{ width: 552, height: "auto", minHeight: 180, maxHeight: 400 }}
          cols={3}
          rowHeight={180}
        >
          {ownedNfts.map((nft) => {
            const targetTokenId = nft.tokenId;

            return (
              <ImageListItem
                key={`select-to-fulfill-${targetTokenId}`}
                onClick={() =>
                  selectedTargetTokenIdList.includes(targetTokenId)
                    ? unselectTokenId(targetTokenId)
                    : selectTokenId(targetTokenId)
                }
                sx={{ cursor: "pointer" }}
              >
                <img
                  src={nft.rawMetadata.image}
                  alt={`Token Id: ${targetTokenId}`}
                />
                <ImageListItemBar
                  subtitle={`Select TokenId: ${targetTokenId}`}
                  actionPosition="left"
                  actionIcon={
                    <IconButton
                      sx={{ color: "white" }}
                      aria-label={`Select TokenID: ${targetTokenId}`}
                      disabled={
                        isReachFulfillBalance &&
                        !selectedTargetTokenIdList.includes(targetTokenId)
                      }
                    >
                      {selectedTargetTokenIdList.includes(targetTokenId) ? (
                        <CheckBoxIcon />
                      ) : (
                        <CheckBoxOutlineBlankIcon />
                      )}
                    </IconButton>
                  }
                />
              </ImageListItem>
            );
          })}
        </ImageList>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
        <LoadingButton
          loading={isTxLoading}
          onClick={() => write()}
          variant="contained"
        >
          Fulfill
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const FulfillByFuturesButton = ({
  futuresTokenId,
  debtTokenId,
  amount,
  maxAmount,
  futuresAddress,
  refreshCallback,
}) => {
  const { activeChain } = useNetwork();
  const chainId = activeChain && activeChain.id;
  const networkKeyName = NETWORK_KEY_NAME[chainId];

  const { data: txData, write } = useContractWrite(
    getContractForWagmi("FuturesFor721", networkKeyName, futuresAddress),
    "fulfillDebtWithFutures",
    {
      args: [futuresTokenId, debtTokenId, amount],
      onSettled: (data, error) => {
        if (data) toast.info(`hash: ${data.hash}`);
      },
      onError: (err) => {
        console.error(err);
      },
    }
  );

  const { isLoading: isTxLoading } = useWaitForTransaction({
    hash: txData && txData.hash,
    wait: txData && txData.wait,
    onError: (err) => {
      console.error(err);
    },
    onSuccess: (data) => {
      toast.info("Fulfilled");
      refreshCallback();
    },
  });

  return (
    <LoadingButton
      loading={isTxLoading}
      onClick={() => write()}
      variant="contained"
      disabled={amount === 0 || amount > maxAmount}
    >
      Fulfill
    </LoadingButton>
  );
};

const FulfillByFuturesDialog = ({
  isOpen,
  closeDialog,
  dataRows,
  debtsBalance,
  debtTokenId,
  futuresAddress,
  refreshCallback,
}) => {
  const now = Date.now() / 1000;
  const [amountMap, setAmountMap] = useState({});
  console.log(amountMap);
  const fulfilledRows = dataRows
    .filter((data) => data.expiryDatetimeSec < now && data.fulfillment > 0)
    .map((data) => {
      return {
        id: data.id,
        expiryDatetimeSec: data.expiryDatetimeSec,
        securityDeposit: data.securityDeposit,
        fulfillment: data.fulfillment,
        action: data.id,
      };
    });

  const columns = [
    {
      field: "expiryDatetimeSec",
      headerName: "Expiry Datetime (UTC)",
      flex: 2,
      width: 300,
      renderCell: (params) => {
        const expiryDatetime = moment(params.row[params.field] * 1000);
        return (
          <Typography>
            {expiryDatetime.utc().format("YYYY-MM-DD HH:mm:ss")}
          </Typography>
        );
      },
    },
    {
      field: "securityDeposit",
      headerName: "Premium",
      type: "number",
      width: 150,
      renderCell: (params) => {
        return (
          <Typography>{`${utils.formatEther(
            params.row[params.field]
          )} ETH`}</Typography>
        );
      },
    },
    {
      field: "fulfillment",
      headerName: "Fulfillment",
      type: "number",
      flex: 1,
      width: 110,
    },
    {
      field: "amount",
      headerName: "Amount",
      type: "number",
      flex: 1,
      width: 110,
      renderCell: (params) => {
        return (
          <FormControl>
            <InputLabel id="fulfill-futures-amount" />
            <Select defaultValue={0}>
              {Array.from(
                Array(Math.min(params.row.fulfillment, debtsBalance) + 1).keys()
              ).map((x) => (
                <MenuItem
                  key={`fulfill-by-futures-amount-${params.row.id}-${x}`}
                  value={x}
                  onClick={(evt) => {
                    let newAmountMap = { ...amountMap };
                    newAmountMap[params.row.id] = evt.target.dataset.value;
                    setAmountMap(newAmountMap);
                  }}
                >
                  {x}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: "action",
      type: "actions",
      width: 300,
      sortable: false,
      flex: 2,
      filterable: false,
      renderCell: (params) => {
        return (
          <FulfillByFuturesButton
            debtTokenId={debtTokenId}
            futuresTokenId={params.row.id}
            futuresAddress={futuresAddress}
            amount={
              amountMap[params.row.id] === undefined
                ? 0
                : parseInt(amountMap[params.row.id])
            }
            maxAmount={params.row.fulfillment}
            refreshCallback={refreshCallback}
          />
        );
      },
    },
  ];

  return (
    <Dialog open={isOpen} onClose={closeDialog} maxWidth="sm" fullWidth={true}>
      <DialogContent>
        <Typography>
          Pay the debt by the expired futures. If the fulfillment pool has no
          enough NFTs, the transaction will be reverted.
        </Typography>
        <Box sx={{ height: 600, width: "100%" }}>
          <Box sx={{ display: "flex", height: "100%" }}>
            <Box sx={{ flexGrow: 1 }}>
              <DataGrid
                rows={fulfilledRows}
                columns={columns}
                disableSelectionOnClick
              />
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

const FulfillByOpenseaDialog = ({
  isOpen,
  closeDialog,
  nftAddress,
  securityDeposit,
}) => {
  const [openseaUrl, setOpenseaUrl] = useState("");
  const dispatch = useDispatch();
  const currentListingInfo = useSelector(
    (state) => state.futures.currentListingInfo
  );

  const isAvailable = currentListingInfo && currentListingInfo.listingPrice;

  const isNeedToPay =
    currentListingInfo &&
    isAvailable &&
    BigNumber.from(securityDeposit).lt(currentListingInfo.listingPrice);
  const listingPriceDiff =
    currentListingInfo && isAvailable
      ? isNeedToPay
        ? BigNumber.from(currentListingInfo.listingPrice).sub(securityDeposit)
        : BigNumber.from(securityDeposit).sub(currentListingInfo.listingPrice)
      : constants.Zero;

  const dispatchToLoadOpenseaListingInfo = () => {
    const tokenId = openseaUrl.split("/").at(-1);
    dispatch(
      loadOpenseaListingInfo({
        targetNftAddress: nftAddress,
        tokenId: tokenId,
      })
    );
  };

  return (
    <Dialog open={isOpen} onClose={closeDialog} maxWidth="sm" fullWidth={true}>
      <DialogContent>
        <Typography>
          Buy an NFT from Opensea to pay the debt directly with the
          premium.(多退少補)
        </Typography>
        <Box width="100%">
          <FormControl fullWidth>
            <TextField
              id="opensea-url-of-fulfilling-nft"
              label="Opensea URL of target NFT"
              width="100%"
              variant="outlined"
              value={openseaUrl}
              onChange={(event) => setOpenseaUrl(event.target.value)}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={dispatchToLoadOpenseaListingInfo}>
                    <GetAppIcon />
                  </IconButton>
                ),
              }}
            />
          </FormControl>
        </Box>
        {currentListingInfo && (
          <Card sx={{ display: "flex" }}>
            <CardMedia
              component="img"
              image={currentListingInfo.imageUrl}
              sx={{ width: "200px", height: "200px" }}
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CardContent sx={{ flex: "1 0 auto" }}>
                <Typography component="div" variant="h5">
                  {isAvailable
                    ? `Price: ${formatEther(
                        currentListingInfo.listingPrice
                      )} ETH`
                    : "Unavailable"}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  <Link href={openseaUrl} target="_blank" rel="noopener">
                    See on Opensea
                  </Link>
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  {`Deposit Premium: ${formatEther(securityDeposit)} ETH`}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  {isAvailable
                    ? isNeedToPay
                      ? `You need to pay ${formatEther(
                          listingPriceDiff
                        )} ETH to fulfill.`
                      : `You will get ${formatEther(
                          listingPriceDiff
                        )} ETH after fulfilled.`
                    : ""}
                  {/* 1ETH will be remained after fulfilled this debt. */}
                </Typography>
              </CardContent>
            </Box>
          </Card>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
        <Button disabled={!isAvailable}>Fulfill</Button>
      </DialogActions>
    </Dialog>
  );
};

export const FulfillButtonAndDialog = ({
  debtsBalance,
  futuresAddress,
  tokenId,
  securityDeposit,
  nftAddress,
  dataRows,
  refreshCallback,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogType, setDialogType] = useState("");
  const isMenuOpen = Boolean(anchorEl);
  const openMenu = (evt) => {
    setAnchorEl(evt.currentTarget);
  };
  const closeMenu = () => setAnchorEl(null);
  const selectItem = (selectedDialogType) => {
    setDialogType(selectedDialogType);
    closeMenu();
  };
  const isDialogOpen = Boolean(dialogType);
  const closeDialog = () => setDialogType("");

  let dialogEl;
  switch (dialogType) {
    case "fulfill-nft":
      dialogEl = (
        <FulfillByNftDialog
          isOpen={isDialogOpen}
          closeDialog={closeDialog}
          debtsBalance={debtsBalance}
          futuresAddress={futuresAddress}
          tokenId={tokenId}
          nftAddress={nftAddress}
          refreshCallback={refreshCallback}
        />
      );
      break;
    case "fulfill-futures":
      dialogEl = (
        <FulfillByFuturesDialog
          isOpen={isDialogOpen}
          closeDialog={closeDialog}
          dataRows={dataRows}
          debtTokenId={tokenId}
          debtsBalance={debtsBalance}
          futuresAddress={futuresAddress}
          refreshCallback={refreshCallback}
        />
      );
      break;
    case "fulfill-opensea":
      dialogEl = (
        <FulfillByOpenseaDialog
          isOpen={isDialogOpen}
          closeDialog={closeDialog}
          nftAddress={nftAddress}
          securityDeposit={securityDeposit}
        />
      );
      break;
    default:
      break;
  }

  return (
    <Box>
      <Button
        id="fulfill-button"
        aria-controls={isMenuOpen ? "open-claim-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? "true" : undefined}
        variant="contained"
        onClick={openMenu}
        disabled={debtsBalance <= 0}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Fulfill
      </Button>
      <Menu
        id="fulfill-button-button"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={closeMenu}
        MenuListProps={{
          "aria-labelledby": "fulfill-button-menu",
        }}
      >
        <MenuItem onClick={() => selectItem("fulfill-nft")}>
          Fulfill by NFT
        </MenuItem>
        <MenuItem onClick={() => selectItem("fulfill-futures")}>
          Fulfill by Futures
        </MenuItem>
        <MenuItem onClick={() => selectItem("fulfill-opensea")}>
          Fulfill from Opensea
        </MenuItem>
      </Menu>
      {dialogEl}
    </Box>
  );
};
