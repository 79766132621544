export const OPENSEA_BASE_URL = {
  mainnet: "https://opensea.io",
  rinkeby: "https://testnets.opensea.io",
};

export const OPENSEA_API_BASE_URL = {
  mainnet: "https://api.opensea.io/api",
  rinkeby: "https://testnets-api.opensea.io/api",
};

export const LOOKSRARE_BASE_URL = {
  mainnet: "https://looksrare.org",
  rinkeby: "https://rinkeby.looksrare.org",
};

export const LOOKSRARE_API_BASE_URL = {
  mainnet: "https://api.looksrare.org/api",
  rinkeby: "https://api-rinkeby.looksrare.org/api",
};
