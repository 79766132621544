import axios from "axios";
import { isAddress } from "ethers/lib/utils";
import { OPENSEA_BASE_URL, OPENSEA_API_BASE_URL } from "../constants/urls";

export const isOpenseaUrl = (url, networkKeyName) => {
  return url.startsWith(OPENSEA_BASE_URL[networkKeyName]);
};

export const fetchNftAddressByOsAssetUrl = async (url, networkKeyName) => {
  if (!isOpenseaUrl(url, networkKeyName)) return null;
  const address = url.split("/")[url.split("/").length - 2];
  return isAddress(address) ? address : null;
};

export const fetchNftAddressByOsCollectionUrl = async (url, networkKeyName) => {
  if (!isOpenseaUrl(url, networkKeyName)) return null;
  const collection_slug = url.split("/").pop();
  return await fetchNftAddressByOsCollectionSlug(collection_slug, networkKeyName);
};

export const fetchNftAddressByOsCollectionSlug = async (slug, networkKeyName) => {
  const collectionInfo = await fetchOsCollectionInfoBySlug(
    slug,
    networkKeyName
  );
  if (!collectionInfo) return null;
  const nftAddress = collectionInfo.primary_asset_contracts[0].address;
  return nftAddress;
};

export const fetchOpenseaInfoByOsContractAddress = async (address, networkKeyName) => {
  if (!networkKeyName) return null;
  const url = `${OPENSEA_API_BASE_URL[networkKeyName]}/v1/asset_contract/${address}`;
  try {
    const response = await axios.get(url);
    const collectionData = response.data.collection;
    return {
      imageUrl:
        collectionData.image_url &&
        collectionData.image_url.replace("s120", "s500"), // get 500x500 image
      name: collectionData.name,
      slug: collectionData.slug,
    };
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const fetchOsCollectionInfoBySlug = async (slug, networkKeyName) => {
  if (!networkKeyName) return null;
  const url = `${OPENSEA_API_BASE_URL[networkKeyName]}/v1/collection/${slug}`;
  try {
    const response = await axios.get(url);
    return response.data.collection;
  } catch (e) {
    console.error(e);
    return null;
  }
};
