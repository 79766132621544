import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Stack,
} from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import { useContractWrite, useNetwork, useWaitForTransaction } from "wagmi";
import { NETWORK_KEY_NAME } from "../constants/networks";
import { getContractForWagmi } from "../utils";

const ClosePositionDialog = ({
  isOpen,
  closeDialog,
  maxAmount,
  futuresAddress,
  tokenId,
  refreshCallback,
}) => {
  const [amount, setAmount] = useState(maxAmount);
  const { activeChain } = useNetwork();
  const chainId = activeChain && activeChain.id;
  const networkKeyName = NETWORK_KEY_NAME[chainId];
  const { data: txData, write } = useContractWrite(
    getContractForWagmi("FuturesFor721", networkKeyName, futuresAddress),
    "closePosition",
    {
      args: [amount, tokenId],
      onSettled: (data, error) => {
        if (data) toast.info(`hash: ${data.hash}`);
      },
      onError: (err) => {
        console.error(err);
      },
    }
  );

  const { isLoading: isTxLoading } = useWaitForTransaction({
    hash: txData && txData.hash,
    wait: txData && txData.wait,
    onError: (err) => {
      console.error(err);
    },
    onSuccess: (data) => {
      toast.info("Position(s) closed.");
      refreshCallback();
      closeDialog();
    },
  });

  return (
    <Dialog open={isOpen} onClose={closeDialog} maxWidth="sm">
      <DialogContent>
        <Stack spacing={1}>
          <Typography>Close the pair of debt and futures.</Typography>
          <FormControl>
            <InputLabel id="demo-simple-select-label">Amount</InputLabel>
            <Select
              labelId="close-amount-select"
              id="close-amount-select"
              value={amount}
              label="close-amount"
              onChange={(event) => setAmount(event.target.value)}
            >
              {[...Array(maxAmount).keys()].map((x) => (
                <MenuItem key={`close-amount-${x + 1}`} value={x + 1}>
                  {x + 1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
        <LoadingButton
          onClick={() => write()}
          variant="contained"
          loading={isTxLoading}
        >
          Close Position
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export const ClosePositionButtonAndDialog = ({
  maxAmount,
  futuresAddress,
  tokenId,
  refreshCallback,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);

  return (
    <Box>
      <Button
        variant="contained"
        disabled={maxAmount === 0}
        onClick={openDialog}
      >
        Close Position
      </Button>
      <ClosePositionDialog
        isOpen={isDialogOpen}
        closeDialog={closeDialog}
        maxAmount={maxAmount}
        futuresAddress={futuresAddress}
        tokenId={tokenId}
        refreshCallback={refreshCallback}
      />
    </Box>
  );
};
