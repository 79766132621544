import {
  Box,
  Card,
  Container,
  Grid,
  Link,
  Stack,
  Typography,
  Tab,
  Tabs,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState, useMemo, useCallback } from "react";
import { FloorPrice } from "../components/FloorPrice";
import { ClaimButtonAndDialog } from "../components/ClaimActions";
import { FulfillButtonAndDialog } from "../components/FulfillActions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ClosePositionButtonAndDialog } from "../components/ClosePositionAction";
import moment from "moment";
import { OpenPositionButtonAndDialog } from "../components/OpenPositionAction";
import { useParams } from "react-router-dom";
import { fetchOpenseaInfoByOsContractAddress } from "../utils/opensea";
import {
  useAccount,
  useContractWrite,
  useNetwork,
  useProvider,
  useWaitForTransaction,
} from "wagmi";
import { NETWORK_KEY_NAME } from "../constants/networks";
import { loadOwnedNftsByAccount } from "../slices/AccountSlice";
import { useDispatch, useSelector } from "react-redux";
import { utils } from "ethers";
import {
  generateFuturesDebtsList,
  getContractDeploymentInfo,
  isExpiredInSeconds,
  isZeroAddress,
} from "../utils";
import {
  loadFulfillmentInfo,
  loadFulfillmentNfts,
  loadFuturesAddress,
} from "../slices/FuturesSlice";

const ActionsGroup = ({
  data,
  futuresAddress,
  nftAddress,
  dataRows,
  refreshCallback,
}) => {
  const isExpired = isExpiredInSeconds(data.expiryDatetimeSec);
  return (
    <Box>
      {isExpired ? (
        <Stack direction="row" gap={1}>
          <ClaimButtonAndDialog
            futuresAddress={futuresAddress}
            futuresBalance={data.futuresBalance}
            tokenId={data.id}
            fulfillmentBalance={data.fulfillment}
            refreshCallback={refreshCallback}
          />
          <ClosePositionButtonAndDialog
            maxAmount={Math.min(data.futuresBalance, data.debtsBalance)}
            futuresAddress={futuresAddress}
            tokenId={data.id}
            refreshCallback={refreshCallback}
          />
        </Stack>
      ) : (
        <Stack direction="row" gap={1}>
          <FulfillButtonAndDialog
            debtsBalance={data.debtsBalance}
            futuresAddress={futuresAddress}
            tokenId={data.id}
            securityDeposit={data.securityDeposit}
            nftAddress={nftAddress}
            dataRows={dataRows}
            refreshCallback={refreshCallback}
          />
          <ClosePositionButtonAndDialog
            maxAmount={Math.min(data.futuresBalance, data.debtsBalance)}
            futuresAddress={futuresAddress}
            tokenId={data.id}
            refreshCallback={refreshCallback}
          />
        </Stack>
      )}
    </Box>
  );
};

const DeployFuturesButton = ({
  networkKeyName,
  nftAddress,
  refreshCallback,
}) => {
  const factoryContract = getContractDeploymentInfo("Factory", networkKeyName);
  const { data: txData, write } = useContractWrite(
    {
      addressOrName: factoryContract && factoryContract.address,
      contractInterface: factoryContract && factoryContract.abi,
    },
    "deployFutures",
    {
      args: [nftAddress],
      onSettled: (data, error) => {
        toast.info(`hash: ${data.hash}`);
      },
      onError: (err) => {
        console.error(err.reason);
      },
    }
  );

  const { isLoading: isTxLoading } = useWaitForTransaction({
    hash: txData && txData.hash,
    wait: txData && txData.wait,
    onError: (err) => {
      console.error(err.reason);
    },
    onSuccess: (data) => {
      toast.info("Futures deployed.");
      refreshCallback();
    },
  });

  return (
    <LoadingButton
      onClick={() => write()}
      loading={isTxLoading}
      variant="contained"
    >
      Create Futures
    </LoadingButton>
  );
};

const DataTab = ({ columns, rows }) => {
  /* TODO: the height of grid can't match the height of content automatically*/
  return (
    <Box sx={{ height: 600, width: "100%" }}>
      <Box sx={{ display: "flex", height: "100%" }}>
        <Box sx={{ flexGrow: 1 }}>
          <DataGrid
            rows={rows}
            columns={columns}
            disableSelectionOnClick
            initialState={{
              sorting: {
                sortModel: [{ field: "expiryDatetimeSec", sort: "asc" }],
              },
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export const FuturesPage = () => {
  // TODO: Put nftAddress, futuresAddress, debtAddress, etc. into redux store.
  const { nftAddress } = useParams();
  const [nftName, setNftName] = useState("");
  const [nftImage, setNftImage] = useState(null);
  const [futuresSlug, setFuturesSlug] = useState("");
  const [debtSlug, setDebtSlug] = useState("");
  const dispatch = useDispatch();
  const { activeChain } = useNetwork();
  const { data: accountData } = useAccount();
  const chainId = activeChain && activeChain.id;
  const networkKeyName = NETWORK_KEY_NAME[chainId];
  const provider = useProvider();
  const ownedFutures = useSelector((state) => state.account.ownedFutures);
  const ownedDebts = useSelector((state) => state.account.ownedDebts);
  const fulfillmentInfo = useSelector((state) => state.futures.fulfillmentInfo);
  const futuresAddress = useSelector((state) => state.futures.futuresAddress);
  const debtAddress = useSelector((state) => state.futures.debtAddress);
  const isFuturesCreated = useMemo(
    () => !isZeroAddress(futuresAddress),
    [futuresAddress]
  );

  const reloadFuturesAddress = useCallback(() => {
    dispatch(
      loadFuturesAddress({
        networkKeyName: networkKeyName,
        provider: provider,
        targetNftAddress: nftAddress,
      })
    );
  }, [dispatch, networkKeyName, nftAddress, provider]);

  const reloadOwnedNft = useCallback(() => {
    if (!accountData) return;
    dispatch(
      loadOwnedNftsByAccount({
        account: accountData.address,
        nftAddress: nftAddress,
        futuresAddress: futuresAddress,
        debtAddress: debtAddress,
      })
    );
  }, [accountData, debtAddress, dispatch, futuresAddress, nftAddress]);

  const dataRows = useMemo(() => {
    if (!ownedFutures && !ownedDebts) return null;
    let data = generateFuturesDebtsList(ownedFutures, ownedDebts);
    if (fulfillmentInfo) {
      for (let row of data) {
        row.fulfillment = fulfillmentInfo[row.id];
      }
    }
    return data;
  }, [ownedFutures, ownedDebts, fulfillmentInfo]);

  useEffect(() => {
    reloadFuturesAddress();
  }, [reloadFuturesAddress]);

  useEffect(() => {
    if (!futuresAddress || !ownedFutures) return;
    dispatch(
      loadFulfillmentInfo({
        networkKeyName: networkKeyName,
        provider: provider,
        futuresAddress: futuresAddress,
        tokenIdList: ownedFutures.map((f) => f.tokenId),
      })
    );
  }, [dispatch, networkKeyName, provider, futuresAddress, ownedFutures]);

  const blockExplorer = `https://rinkeby.etherscan.io/address/${nftAddress}`;
  const [tabIndex, setTabIndex] = useState(0);
  const now = Date.now() / 1000;

  useEffect(() => {
    if (!activeChain || !nftAddress || !futuresAddress || !debtAddress) return;
    if (
      isZeroAddress(nftAddress) ||
      isZeroAddress(futuresAddress) ||
      isZeroAddress(debtAddress)
    )
      return;

    reloadOwnedNft();
    dispatch(
      loadFulfillmentNfts({
        futuresAddress: futuresAddress,
        targetNftAddress: nftAddress,
      })
    );

    fetchOpenseaInfoByOsContractAddress(futuresAddress, networkKeyName).then(
      (data) => {
        setFuturesSlug(data.slug);
      }
    );
    fetchOpenseaInfoByOsContractAddress(debtAddress, networkKeyName).then(
      (data) => {
        setDebtSlug(data.slug);
      }
    );
  }, [
    dispatch,
    activeChain,
    accountData,
    nftAddress,
    futuresAddress,
    debtAddress,
    reloadOwnedNft,
  ]);

  useEffect(() => {
    fetchOpenseaInfoByOsContractAddress(nftAddress, networkKeyName).then(
      (data) => {
        if (!data) return;
        setNftName(data.name);
        setNftImage(data.imageUrl);
      }
    );
  }, [networkKeyName, nftAddress]);

  const columns = [
    {
      field: "expiryDatetimeSec",
      headerName: "Expiry Datetime (UTC)",
      flex: 2,
      width: 200,
      renderCell: (params) => {
        const expiryDatetime = moment(params.row[params.field] * 1000);
        return (
          <Typography>
            {expiryDatetime.utc().format("YYYY-MM-DD HH:mm:ss")}
          </Typography>
        );
      },
    },
    {
      field: "securityDeposit",
      headerName: "Premium",
      type: "number",
      width: 150,
      renderCell: (params) => {
        return (
          <Typography>{`${utils.formatEther(
            params.row[params.field]
          )} ETH`}</Typography>
        );
      },
    },
    {
      field: "futuresBalance",
      headerName: "# of Futures",
      type: "number",
      width: 100,
    },
    {
      field: "debtsBalance",
      headerName: "# of Debts",
      type: "number",
      width: 100,
    },
    {
      field: "fulfillment",
      headerName: "Fulfillment",
      type: "number",
      flex: 1,
      width: 110,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 300,
      sortable: false,
      flex: 2,
      filterable: false,
      renderCell: (params) => {
        return (
          <ActionsGroup
            data={params.row}
            futuresAddress={futuresAddress}
            nftAddress={nftAddress}
            dataRows={dataRows}
            refreshCallback={reloadOwnedNft}
          />
        );
      },
    },
  ];

  const appendActionsToDataRows = (dataRows) =>
    dataRows.map((row) => {
      row["actions"] = row.id;
      return row;
    });

  return (
    <Box>
      <Container sx={{ paddingTop: "60px" }}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          padding="20px"
          width="100%"
        >
          <Grid item>
            <Stack direction="row" spacing={5}>
              <Card
                sx={{ height: "200px", width: "200px", borderRadius: "10px" }}
              >
                <img
                  src={nftImage}
                  alt="NFT"
                  style={{ height: "100%", width: "100%" }}
                />
              </Card>
              <Stack spacing={1}>
                <Typography>{nftName}</Typography>
                <Typography>
                  NFT Contract:
                  <Link href={blockExplorer} target="_blank" rel="noopener">
                    {nftAddress}
                  </Link>
                  <CopyToClipboard
                    text={nftAddress}
                    onCopy={() => toast.info("Address copied.")}
                  >
                    <ContentCopyIcon sx={{ cursor: "pointer" }} />
                  </CopyToClipboard>
                </Typography>
                {isFuturesCreated ? (
                  <Box>
                    <Typography>
                      Futures Contract:
                      <Link href={blockExplorer} target="_blank" rel="noopener">
                        {futuresAddress}
                      </Link>
                      <CopyToClipboard
                        text={futuresAddress}
                        onCopy={() => toast.info("Address copied.")}
                      >
                        <ContentCopyIcon sx={{ cursor: "pointer" }} />
                      </CopyToClipboard>
                    </Typography>
                    <Typography>
                      Debt Contract:
                      <Link href={blockExplorer} target="_blank" rel="noopener">
                        {debtAddress}
                      </Link>
                      <CopyToClipboard
                        text={debtAddress}
                        onCopy={() => toast.info("Address copied.")}
                      >
                        <ContentCopyIcon sx={{ cursor: "pointer" }} />
                      </CopyToClipboard>
                    </Typography>
                  </Box>
                ) : (
                  <DeployFuturesButton
                    networkKeyName={networkKeyName}
                    nftAddress={nftAddress}
                    refreshCallback={reloadFuturesAddress}
                  />
                )}
                <FloorPrice marketName="opensea" nftAddress={nftAddress} />
                <FloorPrice marketName="looksrare" nftAddress={nftAddress} />
                <OpenPositionButtonAndDialog
                  futuresAddress={futuresAddress}
                  refreshCallback={() => {
                    reloadOwnedNft();
                  }}
                />
                <Stack direction="row" gap={1}>
                  <Button
                    variant="contained"
                    disabled={!isFuturesCreated}
                    onClick={() =>
                      window
                        .open(
                          `https://testnets.opensea.io/collection/${futuresSlug}`,
                          "_blank"
                        )
                        .focus()
                    }
                  >
                    Buy Futures on Opensea
                  </Button>
                  <Button
                    variant="contained"
                    disabled={!isFuturesCreated}
                    onClick={() =>
                      window
                        .open(
                          `https://testnets.opensea.io/collection/${debtSlug}`,
                          "_blank"
                        )
                        .focus()
                    }
                  >
                    Buy Debts on Opensea
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tabIndex}>
            <Tab label="Ongoing" value={0} onClick={() => setTabIndex(0)} />
            <Tab label="Expired" value={1} onClick={() => setTabIndex(1)} />
          </Tabs>
        </Box>
        {dataRows && (
          <DataTab
            rows={appendActionsToDataRows(dataRows).filter((data) => {
              const isExpired = isExpiredInSeconds(data.expiryDatetimeSec);
              return tabIndex === 0 ? !isExpired : isExpired;
            })}
            columns={columns}
          />
        )}
      </Container>
      <ToastContainer
        position="bottom-left"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Box>
  );
};
