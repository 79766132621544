import { Grid, Container, TextField, Button } from "@mui/material";
import { isAddress } from "ethers/lib/utils";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { chainId, useNetwork } from "wagmi";
import { NETWORK_KEY_NAME } from "../constants/networks";
import {
  isLooksrareUrl,
  fetchNftAddressByLrAssetUrl,
  fetchNftAddressByLrCollectionUrl,
} from "../utils/looksrare";
import {
  isOpenseaUrl,
  fetchNftAddressByOsAssetUrl,
  fetchNftAddressByOsCollectionSlug,
  fetchNftAddressByOsCollectionUrl,
} from "../utils/opensea";

export const LandingPage = () => {
  const navigate = useNavigate();
  const [searchContent, setSearchContent] = useState("");
  const { activeChain } = useNetwork();
  const chainId = activeChain && activeChain.id;
  const networkKeyName = NETWORK_KEY_NAME[chainId];
  const isUnsupportedChain = activeChain && activeChain.unsupported;

  const lookupNftAddress = async (data) => {
    if (isAddress(data)) return data;

    if (isOpenseaUrl(data, networkKeyName)) {
      const retrieveFunctions = [
        fetchNftAddressByOsAssetUrl,
        fetchNftAddressByOsCollectionUrl,
      ];
      for (const func of retrieveFunctions) {
        const targetAddress = await func(data, networkKeyName);
        if (targetAddress !== null) return targetAddress;
      }
    }

    if (isLooksrareUrl(data, networkKeyName)) {
      const retrieveFunctions = [
        fetchNftAddressByLrCollectionUrl,
        fetchNftAddressByLrAssetUrl,
      ];
      for (const func of retrieveFunctions) {
        const targetAddress = await func(data, networkKeyName);
        if (targetAddress !== null) return targetAddress;
      }
    }

    return await fetchNftAddressByOsCollectionSlug(data, networkKeyName);
  };

  return (
    <Container sx={{ height: "100vh" }}>
      <Grid
        sx={{ height: "100%" }}
        spacing={1}
        alignItems="center"
        justifyContent="center"
        container
      >
        <Grid item>
          <TextField
            sx={{ width: "600px" }}
            label="Contract address, OpenSea URL or LooksRare URL of NFT"
            variant="outlined"
            size="small"
            onChange={(event) => setSearchContent(event.target.value)}
            value={searchContent}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            size="large"
            onClick={async () => {
              const nftAddress = await lookupNftAddress(searchContent);
              if (nftAddress) navigate(`nft/${nftAddress}`);
            }}
          >
            Enter
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
