import { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Typography,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useContractWrite, useNetwork, useWaitForTransaction } from "wagmi";
import { NETWORK_KEY_NAME } from "../constants/networks";
import { getContractForWagmi } from "../utils";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";

const ClaimPremiumsDialogContent = ({
  isOpen,
  closeDialog,
  futuresAddress,
  tokenId,
  futuresBalance,
  refreshCallback,
}) => {
  const { activeChain } = useNetwork();
  const chainId = activeChain && activeChain.id;
  const networkKeyName = NETWORK_KEY_NAME[chainId];
  const { data: txData, write } = useContractWrite(
    getContractForWagmi("FuturesFor721", networkKeyName, futuresAddress),
    "claimFutureDefault",
    {
      args: [tokenId, futuresBalance],
      onSettled: (data, error) => {
        if (data) toast.info(`hash: ${data.hash}`);
      },
      onError: (err) => {
        console.error(err);
      },
    }
  );

  const { isLoading: isTxLoading } = useWaitForTransaction({
    hash: txData && txData.hash,
    wait: txData && txData.wait,
    onError: (err) => {
      console.error(err);
    },
    onSuccess: (data) => {
      toast.info("Claimed");
      refreshCallback();
      closeDialog();
    },
  });

  return (
    <Dialog open={isOpen} onClose={closeDialog} maxWidth="sm" fullWidth={true}>
      <DialogContent>
        <Typography>Claim premiums.</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
        <LoadingButton
          loading={isTxLoading}
          onClick={() => write()}
          variant="contained"
        >
          Claim All
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const ClaimNftsDialogContent = ({
  isOpen,
  closeDialog,
  futuresAddress,
  futuresBalance,
  fulfillmentBalance,
  tokenId,
  refreshCallback,
}) => {
  const fulfillmentNfts = useSelector((state) => state.futures.fulfillmentNfts);
  const maxClaimableAmount = Math.min(fulfillmentBalance, futuresBalance);
  const [selectedTargetTokenIdList, setSelectedTargetTokenIdList] = useState(
    []
  );
  const { activeChain } = useNetwork();
  const chainId = activeChain && activeChain.id;
  const networkKeyName = NETWORK_KEY_NAME[chainId];

  const { data: txData, write } = useContractWrite(
    getContractForWagmi("FuturesFor721", networkKeyName, futuresAddress),
    "claimFutureTarget",
    {
      args: [selectedTargetTokenIdList, tokenId],
      onSettled: (data, error) => {
        if (data) toast.info(`hash: ${data.hash}`);
      },
      onError: (err) => {
        console.error(err);
      },
    }
  );

  const { isLoading: isTxLoading } = useWaitForTransaction({
    hash: txData && txData.hash,
    wait: txData && txData.wait,
    onError: (err) => {
      console.error(err);
    },
    onSuccess: (data) => {
      toast.info("Claimed");
      refreshCallback();
      closeDialog();
    },
  });

  const isReachClaimableBalance =
    selectedTargetTokenIdList.length >= maxClaimableAmount;

  const selectTokenId = (selectedTargetTokenId) => {
    if (isReachClaimableBalance) return;
    setSelectedTargetTokenIdList([
      ...selectedTargetTokenIdList,
      selectedTargetTokenId,
    ]);
  };

  const unselectTokenId = (unselectedTargetTokenId) => {
    setSelectedTargetTokenIdList(
      selectedTargetTokenIdList.filter(
        (targetTokenId) => targetTokenId !== unselectedTargetTokenId
      )
    );
  };

  return (
    <Dialog open={isOpen} onClose={closeDialog} maxWidth="sm" fullWidth={true}>
      <DialogContent>
        <Typography>
          Select only one NFT from the fulfilled pool. It would charge 1% of the
          premium.
        </Typography>
        <ImageList
          sx={{ width: 552, height: "auto", minHeight: 180, maxHeight: 400 }}
          cols={3}
          rowHeight={180}
        >
          {fulfillmentNfts.map((nft) => {
            const targetNftTokenId = nft.tokenId;
            return (
              <ImageListItem
                key={`select-to-claim-${targetNftTokenId}`}
                onClick={() =>
                  selectedTargetTokenIdList.includes(targetNftTokenId)
                    ? unselectTokenId(targetNftTokenId)
                    : selectTokenId(targetNftTokenId)
                }
                sx={{ cursor: "pointer" }}
              >
                <img
                  src={nft.rawMetadata.image}
                  alt={`tokenId: ${targetNftTokenId}`}
                  loading="lazy"
                />
                <ImageListItemBar
                  subtitle={`TokenId: ${targetNftTokenId}`}
                  actionPosition="left"
                  actionIcon={
                    <IconButton
                      sx={{ color: "white" }}
                      aria-label={`Select TokenID: ${targetNftTokenId}`}
                      disabled={
                        !selectedTargetTokenIdList.includes(targetNftTokenId) &&
                        isReachClaimableBalance
                      }
                    >
                      {selectedTargetTokenIdList.includes(targetNftTokenId) ? (
                        <CheckBoxIcon />
                      ) : (
                        <CheckBoxOutlineBlankIcon />
                      )}
                    </IconButton>
                  }
                />
              </ImageListItem>
            );
          })}
        </ImageList>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Cancel</Button>
        <LoadingButton
          loading={isTxLoading}
          onClick={() => write()}
          variant="contained"
        >
          Claim
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export const ClaimButtonAndDialog = ({
  futuresAddress,
  futuresBalance,
  tokenId,
  fulfillmentBalance,
  refreshCallback,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogType, setDialogType] = useState("");
  const isMenuOpen = Boolean(anchorEl);
  const openMenu = (evt) => {
    setAnchorEl(evt.currentTarget);
  };
  const closeMenu = () => setAnchorEl(null);
  const selectItem = (selectedDialogType) => {
    setDialogType(selectedDialogType);
    closeMenu();
  };
  const isDialogOpen = Boolean(dialogType);
  const closeDialog = () => setDialogType("");

  let dialogEl;
  switch (dialogType) {
    case "claim-premiums":
      dialogEl = (
        <ClaimPremiumsDialogContent
          isOpen={isDialogOpen}
          closeDialog={closeDialog}
          futuresAddress={futuresAddress}
          futuresBalance={futuresBalance}
          tokenId={tokenId}
          refreshCallback={refreshCallback}
        />
      );
      break;
    case "claim-nfts":
      dialogEl = (
        <ClaimNftsDialogContent
          isOpen={isDialogOpen}
          closeDialog={closeDialog}
          futuresAddress={futuresAddress}
          futuresBalance={futuresBalance}
          fulfillmentBalance={fulfillmentBalance}
          tokenId={tokenId}
          refreshCallback={refreshCallback}
        />
      );
      break;
    default:
      break;
  }

  //  const claimButton = fulfillmentBalance === 0 ? <Button
  //  id="claim-premiums" onClick={open}

  return (
    <Box>
      <Button
        id={fulfillmentBalance === 0 ? "claim-premiums" : "claim-nfts"}
        variant="contained"
        onClick={() =>
          fulfillmentBalance === 0
            ? setDialogType("claim-premiums")
            : setDialogType("claim-nfts")
        }
        disabled={futuresBalance === 0}
      >
        {fulfillmentBalance === 0 ? "Claim Premiums" : "Claim Nfts"}
      </Button>
      {dialogEl}
    </Box>
  );
};
