import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import accountReducer from "./slices/AccountSlice";
import futuresReducer from "./slices/FuturesSlice";
import { isDevelopment } from "./utils";

const loggerMiddleware = createLogger();

export default configureStore({
  reducer: {
    account: accountReducer,
    futures: futuresReducer,
  },
  middleware: (getDefaultMiddleware) => {
    let middleware = getDefaultMiddleware();
    if (isDevelopment()) {
      middleware = middleware.concat(loggerMiddleware);
    }
    return middleware;
  },
});
