import { getNftMetadata, getNftsForOwner } from "@alch/alchemy-sdk";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { BigNumber, constants, Contract, ethers } from "ethers";
import { Network, OpenSeaSDK } from "opensea-js";
import { getContractDeploymentInfo } from "../utils";
import { alchemySdk } from "../constants";

const getFuturesContract = (address, networkKeyName, provider) => {
  const deploymentInfo = getContractDeploymentInfo(
    "FuturesFor721",
    networkKeyName
  );
  if (!deploymentInfo) return null;
  return new Contract(address, deploymentInfo.abi, provider);
};

const getFactoryContract = (networkKeyName, provider) => {
  const deploymentInfo = getContractDeploymentInfo("Factory", networkKeyName);
  if (!deploymentInfo) return null;
  return new Contract(deploymentInfo.address, deploymentInfo.abi, provider);
};

export const loadFuturesAddress = createAsyncThunk(
  "futures/loadFuturesAddress",
  async ({ targetNftAddress, networkKeyName, provider }) => {
    const factoryContract = getFactoryContract(networkKeyName, provider);
    const futuresAddress = await factoryContract.nft2Futures(targetNftAddress);

    if (!futuresAddress || futuresAddress === constants.AddressZero) {
      console.log("Futures has not been created.");
      return null;
    }

    const futuresContract = getFuturesContract(
      futuresAddress,
      networkKeyName,
      provider
    );
    const debtAddress = await futuresContract.debt();

    return {
      futuresAddress: futuresAddress,
      debtAddress: debtAddress,
    };
  }
);

export const loadFulfillmentInfo = createAsyncThunk(
  "futures/loadFulfillmentInfo",
  async ({ tokenIdList, futuresAddress, networkKeyName, provider }) => {
    const futuresContract = getFuturesContract(
      futuresAddress,
      networkKeyName,
      provider
    );

    const fulfillmentInfo = (
      await Promise.all(
        tokenIdList.map(async (tokenId) => {
          const fulfillmentCount = await futuresContract.fulfillment(tokenId);
          return {
            tokenId: tokenId,
            fulfillmentCount: fulfillmentCount.toNumber(),
          };
        })
      )
    ).reduce((pre, cur) => {
      pre[cur.tokenId] = cur.fulfillmentCount;
      return pre;
    }, {});

    return fulfillmentInfo;
  }
);

export const loadFulfillmentNfts = createAsyncThunk(
  "futures/loadFulfillmentNfts",
  async ({ futuresAddress, targetNftAddress }) => {
    try {
      const response = await getNftsForOwner(alchemySdk, futuresAddress, {
        contractAddresses: targetNftAddress,
      });
      return response.ownedNfts;
    } catch (err) {
      console.error(err);
      return null;
    }
  }
);

export const loadOpenseaListingInfo = createAsyncThunk(
  "futures/loadOpenseaListingInfo",
  async ({ targetNftAddress, tokenId }) => {
    const nftMetadata = await getNftMetadata(
      alchemySdk,
      targetNftAddress,
      tokenId
    );

    const imageUrl = nftMetadata.rawMetadata.image;

    const provider = new ethers.providers.AlchemyProvider(
      "rinkeby",
      "nJvGriP84oYwbDNWJk7871GAnumzizdg"
    );

    const openseaSdk = new OpenSeaSDK(provider, {
      networkName: Network.Rinkeby,
      // apiKey: OPENSEA_API_KEY,
    });

    const availableListings = (
      await openseaSdk.api.getOrders({
        side: "ask",
        assetContractAddress: targetNftAddress,
        tokenIds: [tokenId],
        protocol: "seaport",
      })
    ).orders;

    availableListings.sort((a, b) =>
      BigNumber.from(a.currentPrice).lt(b.currentPrice)
    );

    const listingPrice =
      availableListings.length > 0 ? availableListings[0].currentPrice : null;

    return {
      listingPrice: listingPrice,
      imageUrl: imageUrl,
    };
  }
);

const futuresSlice = createSlice({
  name: "futures",
  initialState: {
    isLoading: false,
    targetNftAddress: null,
    futuresAddress: constants.AddressZero,
    debtAddress: constants.AddressZero,
    fulfillmentInfo: {},
    fulfillmentNfts: [],
    currentListingInfo: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadFuturesAddress.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(loadFuturesAddress.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.futuresAddress = action.payload.futuresAddress;
          state.debtAddress = action.payload.debtAddress;
        }
        return state;
      })
      .addCase(loadFuturesAddress.rejected, (state, { error }) => {
        console.error(error);
        state.isLoading = false;
        return state;
      })
      .addCase(loadFulfillmentInfo.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(loadFulfillmentInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.fulfillmentInfo = action.payload;
        }
        return state;
      })
      .addCase(loadFulfillmentInfo.rejected, (state, { error }) => {
        console.error(error);
        state.isLoading = false;
        return state;
      })
      .addCase(loadFulfillmentNfts.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(loadFulfillmentNfts.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.fulfillmentNfts = action.payload;
        }
        return state;
      })
      .addCase(loadFulfillmentNfts.rejected, (state, { error }) => {
        state.isLoading = false;
        console.error(error);
      })
      .addCase(loadOpenseaListingInfo.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(loadOpenseaListingInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.currentListingInfo = action.payload;
        }
        return state;
      })
      .addCase(loadOpenseaListingInfo.rejected, (state, { error }) => {
        state.isLoading = false;
        state.currentListingInfo = null;
        return state;
      });
  },
});

const futuresState = (state) => state.futures;

export default futuresSlice.reducer;
export const getFuturesState = createSelector(
  futuresState,
  (futures) => futures
);
