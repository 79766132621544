import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Grid,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useState } from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import FastForwardIcon from "@mui/icons-material/FastForward";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import {
  convertMillisecondsToSeconds,
  formatPremium,
  getContractDeploymentInfo,
  getNextFridayUTC,
} from "../utils";
import moment from "moment";
import { constants, utils } from "ethers";
import { useContractWrite, useNetwork, useWaitForTransaction } from "wagmi";
import { NETWORK_KEY_NAME } from "../constants/networks";
import { toast } from "react-toastify";

export const OpenPositionButtonAndDialog = ({
  futuresAddress,
  refreshCallback,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [premium, setPremium] = useState("0");
  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);
  const [expiryDatetimeMsUTC, setExpiryDatetimeMsUTC] = useState(
    getNextFridayUTC()
  );
  const [isCustomExpiry, setIsCustomExpiry] = useState(false);
  // Note: This is local time, should convert to UTC time.
  const [customExpiryDatetimeMs, setCustomExpiryDatetimeMs] = useState(null);
  const [amount, setAmount] = useState("0");

  const { activeChain } = useNetwork();
  const chainId = activeChain && activeChain.id;
  const networkKeyName = NETWORK_KEY_NAME[chainId];
  const futuresContract =
    networkKeyName && getContractDeploymentInfo("FuturesFor721", networkKeyName);
  const { data: mintTxData, write } = useContractWrite(
    {
      addressOrName: futuresAddress,
      contractInterface: futuresContract && futuresContract.abi,
    },
    "openPosition",
    {
      overrides: {
        value: utils.parseEther(premium).mul(amount),
      },
      args: [
        amount,
        formatPremium(premium),
        convertMillisecondsToSeconds(expiryDatetimeMsUTC),
        /* _data= */ [],
      ],
      onSettled: (data, error) => {
        if (data) toast.info(`hash: ${data.hash}`);
      },
      onError: (err) => {
        console.error(err);
      },
    }
  );

  const { isLoading: isTxLoading } = useWaitForTransaction({
    hash: mintTxData && mintTxData.hash,
    wait: mintTxData && mintTxData.wait,
    onError: (err) => {
      console.error(err);
    },
    onSuccess: (data) => {
      console.log("Position(s) opened.");
      toast.info("Position(s) opened.");
      refreshCallback();
      closeModal();
    },
  });

  const increaseExpiryOneWeek = () => {
    const newExpiryDatetime = moment(expiryDatetimeMsUTC)
      .add(7, "days")
      .valueOf();
    setExpiryDatetimeMsUTC(newExpiryDatetime);
  };

  const decreaseExpiryOneWeek = () => {
    const newExpiryDatetime = moment(expiryDatetimeMsUTC)
      .subtract(7, "days")
      .valueOf();
    setExpiryDatetimeMsUTC(newExpiryDatetime);
  };

  const increaseExpiryOneMonth = () => {
    const newExpiryDatetime = moment(expiryDatetimeMsUTC)
      .add(1, "months")
      .valueOf();
    setExpiryDatetimeMsUTC(newExpiryDatetime);
  };

  const decreaseExpiryOneMonth = () => {
    const newExpiryDatetime = moment(expiryDatetimeMsUTC)
      .subtract(1, "months")
      .valueOf();
    setExpiryDatetimeMsUTC(newExpiryDatetime);
  };

  const onCustomExpiryChange = (value) => {
    setCustomExpiryDatetimeMs(value);

    // construct UTC time
    const x = new Date(value);
    setExpiryDatetimeMsUTC(
      Date.UTC(
        x.getFullYear(),
        x.getMonth(),
        x.getDate(),
        x.getHours(),
        x.getMinutes(),
        0
      )
    );
  };

  const onPremiumChange = (event) => {
    // round unit is 0.05
    const inputValue = event.target.value;
    if (event.target.value === "") {
      setPremium("0");
      return;
    }

    const hasPoint = inputValue.includes(".");
    const integerPart = parseInt(inputValue.split(".")[0]);
    const decimalPart = hasPoint ? inputValue.split(".")[1] : "";

    switch (decimalPart.length) {
      case 0:
        setPremium(integerPart + (hasPoint ? "." : ""));
        break;
      case 1:
        setPremium(integerPart + "." + decimalPart[0]);
        break;
      case 2:
        setPremium(
          integerPart +
            "." +
            decimalPart[0] +
            (decimalPart[1] === "0" ? "0" : "5")
        );
        break;

      default:
        setPremium(integerPart + "." + decimalPart.slice(0, 2));
    }
  };

  const onAmountChange = (event) => {
    const value = event.target.value;
    if (value === "") setAmount("0");

    const valueInt = parseInt(value);
    if (valueInt >= 0 || !isNaN(valueInt)) setAmount(valueInt.toString());
  };

  return (
    <Box>
      <LoadingButton
        loading={isTxLoading}
        disabled={futuresAddress === constants.AddressZero}
        variant="contained"
        onClick={openModal}
      >
        Open Position
      </LoadingButton>
      <Dialog open={isOpen} onClose={closeModal}>
        <DialogContent>
          <Box component="form">
            <Stack spacing={1}>
              <FormControlLabel
                control={
                  <Switch
                    onChange={(event) => {
                      const isChecked = event.target.checked;
                      setIsCustomExpiry(isChecked);
                      if (isChecked) {
                        setCustomExpiryDatetimeMs(expiryDatetimeMsUTC);
                      } else {
                        setExpiryDatetimeMsUTC(
                          getNextFridayUTC(customExpiryDatetimeMs)
                        );
                      }
                    }}
                    checked={isCustomExpiry}
                  />
                }
                label="Custom Expiry Date"
              />
              <FormControl sx={{ width: "300px" }}>
                {isCustomExpiry ? (
                  <DateTimePicker
                    label="Expiry Date (UTC)"
                    inputFormat="YYYY-MM-DD HH:mm:ss"
                    value={
                      customExpiryDatetimeMs
                        ? customExpiryDatetimeMs
                        : Date.now() - (Date.now() % 60000) // force to 0 second
                    }
                    onChange={onCustomExpiryChange}
                    renderInput={(params) => {
                      params.inputProps["style"] = { textAlign: "center" };
                      return (
                        <TextField
                          {...params}
                          error={expiryDatetimeMsUTC < Date.now()}
                        />
                      );
                    }}
                  />
                ) : (
                  <TextField
                    id="expiry-preset"
                    label="Expiry Date (UTC 00:00)"
                    value={moment(expiryDatetimeMsUTC).format("YYYY-MM-DD")}
                    error={expiryDatetimeMsUTC < Date.now()}
                    InputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9.]*",
                      inputProps: {
                        style: { width: "100px", textAlign: "center" },
                      },
                      startAdornment: (
                        <Stack direction="row">
                          <IconButton onClick={decreaseExpiryOneMonth}>
                            <FastRewindIcon />
                          </IconButton>
                          <IconButton onClick={decreaseExpiryOneWeek}>
                            <ArrowLeftIcon />
                          </IconButton>
                        </Stack>
                      ),
                      endAdornment: (
                        <Stack direction="row">
                          <IconButton onClick={increaseExpiryOneWeek}>
                            <ArrowRightIcon />
                          </IconButton>
                          <IconButton onClick={increaseExpiryOneMonth}>
                            <FastForwardIcon />
                          </IconButton>
                        </Stack>
                      ),
                    }}
                  />
                )}
              </FormControl>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <FormControl sx={{ width: "140px" }}>
                  <TextField
                    width="50px"
                    required
                    id="premium"
                    label="Premium"
                    onChange={onPremiumChange}
                    value={premium}
                    error={parseFloat(premium) <= 0}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "right" },
                      },
                      inputMode: "numeric",
                      pattern: "[0-9.]*",
                      endAdornment: (
                        <InputAdornment position="end">ETH</InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <FormControl sx={{ width: "140px" }}>
                  <TextField
                    required
                    id="amount"
                    label="Amount"
                    type="number"
                    value={amount}
                    error={parseInt(amount) <= 0}
                    onChange={onAmountChange}
                    InputProps={{
                      inputProps: {
                        style: { textAlign: "center" },
                      },
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                  />
                </FormControl>
              </Grid>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>Cancel</Button>
          <LoadingButton
            loading={isTxLoading}
            variant="contained"
            onClick={() => {
              write();
            }}
          >
            Open Position
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
