import "./App.css";
import { Box } from "@mui/material";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { Header } from "./views/Header";
import { LandingPage } from "./views/LandingPage";
import { FuturesPage } from "./views/FuturesPage";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import store from "./store";
import {
  WagmiConfig,
  createClient,
  defaultChains,
  configureChains,
} from "wagmi";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { InjectedConnector } from "wagmi/connectors/injected";
import { Provider } from "react-redux";

// Configure chains & providers with the Alchemy provider.
// Two popular providers are Alchemy (alchemy.com) and Infura (infura.io)
const alchemyId = process.env.REACT_APP_ALCHEMY_ID;
const { chains, provider, webSocketProvider } = configureChains(defaultChains, [
  alchemyProvider({ alchemyId }),
  publicProvider(),
]);

// Set up client
const wagmiClient = createClient({
  autoConnect: true,
  connectors: [
    new InjectedConnector({
      chains,
      options: {
        name: "Injected",
        shimDisconnect: true,
      },
    }),
  ],
  provider,
  webSocketProvider,
});

const MainUI = () => {
  return (
    <Box sx={{ background: "#F5F5F5", color: "#121212", minHeight: "100vh" }}>
      <Header />
      <Outlet />
    </Box>
  );
};

function App() {
  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <WagmiConfig client={wagmiClient}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<MainUI />}>
                <Route index element={<LandingPage />} />
                <Route path="nft/:nftAddress" element={<FuturesPage />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </WagmiConfig>
      </LocalizationProvider>
    </Provider>
  );
}

export default App;
