import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { alchemySdk } from "../constants";
import { getNftsForOwner } from "@alch/alchemy-sdk";

export const loadOwnedNftsByAccount = createAsyncThunk(
  "account/loadOwnedNftsByAccount",
  async ({ account, nftAddress, futuresAddress, debtAddress }) => {
    console.log(account);
    nftAddress = nftAddress.toLowerCase();
    futuresAddress = futuresAddress.toLowerCase();
    debtAddress = debtAddress.toLowerCase();
    try {
      const response = await getNftsForOwner(alchemySdk, account, {
        contractAddresses: [nftAddress, futuresAddress, debtAddress].filter(
          (address) => !!address
        ),
      });
      console.log(response)
      const ownedFutures = futuresAddress
        ? response.ownedNfts.filter(
            (data) => data.contract.address === futuresAddress
          )
        : [];
      const ownedDebts = debtAddress
        ? response.ownedNfts.filter(
            (data) => data.contract.address === debtAddress
          )
        : [];
      const ownedNfts = response.ownedNfts.filter(
        (data) => data.contract.address === nftAddress
      );
      return {
        ownedNfts: ownedNfts,
        ownedFutures: ownedFutures,
        ownedDebts: ownedDebts,
      };
    } catch (err) {
      console.error(err);
      Promise.reject(err);
    }
  }
);

const accountSlice = createSlice({
  name: "account",
  initialState: {
    isLoading: false,
    ownedNfts: [],
    ownedFutures: [],
    ownedDebts: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadOwnedNftsByAccount.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loadOwnedNftsByAccount.fulfilled, (state, action) => {
        state.isLoading = false;
        if (!action.payload) return state;
        state.ownedNfts = action.payload.ownedNfts;
        state.ownedFutures = action.payload.ownedFutures;
        state.ownedDebts = action.payload.ownedDebts;
        return state;
      })
      .addCase(loadOwnedNftsByAccount.rejected, (state, { error }) => {
        state.isLoading = false;
      });
  },
});

const accountState = (state) => state.account;

export default accountSlice.reducer;
export const getAccountState = createSelector(
  accountState,
  (account) => account
);
